import { AxiosResponse } from "axios"
import React, { createContext, useContext, useEffect, useState } from "react"
import { dateRanges, TransactionsResponse } from "../types/Api"
import { filterTokens, getDataPeriod, getDataPeriodTotals, getDateLabel, getDateString, getMonthNumber, getMonthsSinceJanuary, getYearMonthsAgo, useTransactionsRequest } from "../util"
import { AuthContext } from "./AuthContext"
import { FilterContext } from "./FilterContext"

const defaultTotals = {
	last7Days: {swaps: {}, dashboards: {}},
	last30Days: {swaps: {}, dashboards: {}},
	thisMonth: {swaps: {}, dashboards: {}},
	previousMonths: [],
}

export const TransactionsContext = createContext<TransactionsContextData>({
	transactions: {swaps: {}, dashboards: {}},
	loading: true,
	transactionTotals: defaultTotals,
	getTotalTransactions: () => {},
	totalsLoading: false,
	totalsError: false
})

export interface TransactionTotals {
	last7Days: TransactionsResponse,
	last30Days: TransactionsResponse,
	thisMonth: TransactionsResponse,
	previousMonths: TransactionsResponse[],
}

export interface TransactionsContextData {
	transactions: TransactionsResponse,
	loading: boolean,
	transactionTotals: TransactionTotals,
	getTotalTransactions: Function,
	totalsLoading: boolean
	totalsError: boolean,
}

export const TransactionsContextWrapper: React.FC = ({ children }) => {
	const transactionsRequest = useTransactionsRequest()
	const [ transactions, setTransactions ] = useState<TransactionsResponse | undefined>()
	const [ transactionTotals, setTransactionTotals ] = useState<TransactionTotals>()
	const [ fetching, setFetching ] = useState(false)
	const [ totalsFetching, setTotalsFetching ] = useState(false)
	const [ totalsError, setTotalsError ] = useState(false)

	const { loggedIn, user } = useContext(AuthContext)
	const { dateUnit, startDate, endDate } = useContext(FilterContext)

	useEffect(() => {
		let isSubscribed = true;
		
		if (!loggedIn || !user?.isEmailVerified || user?.requiresPasswordChange) return;
		
		setFetching(true)
		transactionsRequest.sendRequest({
			startDate,
			endDate,
			unit: dateUnit
		}).then((res) => {
			if (!isSubscribed) return;
			let castedRes = res as AxiosResponse<TransactionsResponse>
			if (castedRes.data) setTransactions(castedRes.data)
		}).finally(() => {
			if (isSubscribed) setFetching(false)
		})

		return () => {
			isSubscribed = false;
		}
	}, [loggedIn, dateUnit, endDate, startDate, user?.isEmailVerified, user?.requiresPasswordChange])

	const getTotalTransactions = () => {
		setTotalsFetching(true)
		let promises: Promise<any>[] = []
		
		// First promise for last 30 days
		promises.push(transactionsRequest.sendRequest({
			startDate: new Date(Date.now() - 30*24*3600*1000).toISOString(),
			unit: dateUnit
		}).then((res) => {
			let castedRes = res as AxiosResponse<TransactionsResponse>
			if (!castedRes?.data) return;
			
			setTransactionTotals((prevTotals) => ({
				...prevTotals,
				last30Days: {
					dashboards: castedRes.data.dashboards,
					swaps: castedRes.data.swaps,
				},
				last7Days: {
					dashboards: getDataPeriod(castedRes.data.dashboards, [0, 7], "date"),
					swaps: getDataPeriod(castedRes.data.swaps, [0, 7], "date"),
				},
			}) as TransactionTotals)
		}))

		// Second promise for this month
		const currentDate = new Date()
		const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
		
		promises.push(transactionsRequest.sendRequest({
			startDate: firstDayOfMonth.toISOString(),
			unit: dateUnit
		}).then((res) => {
			let castedRes = res as AxiosResponse<TransactionsResponse>
			if (!castedRes?.data) return;
			
			setTransactionTotals((prevTotals) => ({
				...prevTotals,
				thisMonth: {
					swaps: castedRes.data.swaps,
					dashboards: castedRes.data.dashboards,
				},
			}) as TransactionTotals)
		}))

		// Previous months promises
		const monthsSince = getMonthsSinceJanuary()
		if (monthsSince > 0) {
			const previousMonthsPromises = Array.from({ length: monthsSince }, (_, i) => {
				const startDate = new Date()
				startDate.setMonth(startDate.getMonth() - (i + 1))
				startDate.setDate(1)
				
				const endDate = new Date(startDate)
				endDate.setMonth(endDate.getMonth() + 1)
				endDate.setDate(0)  // Last day of the month
				
				return transactionsRequest.sendRequest({
					startDate: startDate.toISOString(),
					endDate: endDate.toISOString(),
					unit: dateUnit
				}).then((res) => {
					let castedRes = res as AxiosResponse<TransactionsResponse>
					if (!castedRes?.data) return;
					
					setTransactionTotals((prevTotals) => {
						const newPreviousMonths = [...(prevTotals?.previousMonths || [])]
						newPreviousMonths[i] = castedRes.data
						return {
							...prevTotals,
							previousMonths: newPreviousMonths
						} as TransactionTotals
					})
				})
			})
			promises = promises.concat(previousMonthsPromises)
		}

		Promise.all(promises)
			.then(() => {
				setTotalsFetching(false)
			})
			.catch((err) => {
				console.error("Error fetching transactions:", err)
				setTotalsFetching(false)
				setTotalsError(true)
			})
	}

	const TransactionsData: TransactionsContextData = {
		transactions: transactions || defaultTotals.last30Days,
		transactionTotals: transactionTotals || defaultTotals,
		loading: fetching || !transactionsRequest.fetchedAt,
		getTotalTransactions,
		totalsLoading: totalsFetching,
		totalsError
	}

	return (
		<TransactionsContext.Provider value={TransactionsData}>
			{children}
		</TransactionsContext.Provider>
	)
}